<template>
  <div>
    <div class="pa24">
      <!-- <div class="d-flex">
                <div style="margin-left: auto">
                    <el-button class="ma mr10" type="primary" @click="addParkFun" :disabled="!companyId">关联园区
                    </el-button>
                </div>
            </div> -->
      <commonTable
        :tableData="tableData"
        :loading="tableDataLoading"
        :paginationVisible="false"
      >
        <template v-slot:table>
          <el-table-column
            align="center"
            label="进驻园区/社团"
            show-overflow-tooltip
            ><template slot-scope="scope">
              <div v-if="scope.row.parkName">
                {{ scope.row.parkName }}
              </div>
              <div v-else>
                {{ scope.row.associationName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="进驻类型"
            show-overflow-tooltip
            width="80"
            ><template slot-scope="scope">
              <el-tag v-if="scope.row.parkId">园区</el-tag>
              <el-tag v-else-if="scope.row.associationId">社团</el-tag>
              <el-tag v-else>-</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="typeTitle"
            align="center"
            label="代理类型"
            show-overflow-tooltip
          />
          <el-table-column
            prop="endTime"
            align="center"
            label="到期时间"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div v-if="scope.row.status === 1">
                {{ scope.row.endTime ? scope.row.endTime : "待付款" }}
              </div>
              <div v-else>
                {{ scope.row.endTime ? scope.row.endTime : "还未通过审核" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            align="center"
            label="审核状态"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <!-- <div class="corange"
                                v-if="scope.row.status == 2 || scope.row.status == 4 || scope.row.status == 5">{{
                statusObj[scope.row.status]
            }}</div>
                            <div v-else>{{ statusObj[scope.row.status] }}</div> -->
              <el-tag
                type="danger"
                v-if="
                  scope.row.status == 2 ||
                  scope.row.status == 4 ||
                  scope.row.status == 5
                "
                >{{ statusObj[scope.row.status] }}</el-tag
              >
              <el-tag v-else>{{ statusObj[scope.row.status] }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="150">
            <template slot-scope="scope">
              <el-button
                type="text"
                v-if="scope.row.status == 1"
                @click="showApplyModelFun(scope.row)"
                >详 情</el-button
              >
              <el-button
                type="text"
                v-if="scope.row.status === 3"
                @click="showUpdateModelFun(scope.row)"
              >
                编 辑
              </el-button>
              <el-button
                type="text"
                v-else
                @click="showUpdateModelFun(scope.row)"
                >修改申请</el-button
              >
            </template>
          </el-table-column>
        </template>
      </commonTable>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      title="修改申请信息"
      :visible.sync="showUpdateModel"
      :destroy-on-close="true"
      width="350px"
    >
      <el-form
        :model="formData"
        :rules="rules"
        ref="formData"
        label-width="80px"
        class="ruleForm"
      >
        <el-form-item label="代理类型" prop="typeLinkIdList">
          <el-select
            v-model="formData.typeLinkIdList"
            multiple
            :loading="loadingType"
            style="width: 240px"
            filterable
            remote
            placeholder="请选择代理类型"
          >
            <el-option
              v-for="item in typeData"
              :key="item.linkId"
              :label="item.typeName"
              :value="item.linkId"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            class="save"
            type="primary"
            @click="updateFun()"
            :loading="loadingBtn"
            >修改申请</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      title="详情信息"
      :visible.sync="showServiceApplyModel"
      :destroy-on-close="true"
      width="500px"
      center
    >
      <el-form
        :model="serviceApplyDetails"
        ref="serviceApplyDetails"
        label-width="150px"
        v-loading="serviceDetailsLoading"
      >
        <el-form-item label="公司名称：">
          <div>{{ serviceApplyDetails.companyName }}</div>
        </el-form-item>
        <el-form-item label="联系人：">
          <div>{{ serviceApplyDetails.name }}</div>
        </el-form-item>
        <el-form-item label="联系电话：">
          <div>{{ serviceApplyDetails.phone }}</div>
        </el-form-item>
        <el-form-item label="代理类型：">
          <el-tag
            class="mr5"
            v-for="(row, index) in serviceApplyDetails.typeList"
            size="medium"
            :key="index"
          >
            {{ row.typeName }}</el-tag
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getParkIntoCompanyList,
  setParkIntoCompany,
  getParkIntoCompany,
} from "@/api/propertyServices";
import { getParkShopTypeLinkList } from "@/api/parkManagement";
import commonTable from "@/components/common/commonTable";
export default {
  name: "corporateInformation",
  components: { commonTable },
  data() {
    return {
      //修改申请配置
      companyId: 0, //公司id
      formData: {
        //关联信息
        typeLinkIdList: [],
        parkId: "",
        associationId:"",
      },
      showUpdateModel: false, //设置显示进驻表单
      rules: {
        //验证提交数据
        typeLinkIdList: [
          { required: true, message: "请选择代理类型", trigger: "change" },
        ],
      },
      statusObj: {
        0: "待审核",
        1: "待付款",
        2: "不通过",
        3: "正常",
        4: "到期",
        5: "禁用",
      },
      typeData: [],
      tableData: [], //服务大厅数据
      tableDataLoading: false, //服务大厅数据加载状态
      loadingType: false, //园区数据加载状态
      //详情配置
      showServiceApplyModel: false, //修改申请模态框显示状态
      serviceDetailsLoading: false, //修改申请模态框加载状态
      serviceApplyDetails: {
        //修改申请详情
        companyName: "",
        name: "",
        phone: "",
        typeList: [],
        status: "",
      },
      loadingBtn: false,
    };
  },
  async mounted() {
    this.companyId = this.$store.state.loginRoot.userInfo.companyId;
    this.getParkIntoCompanyList();
  },
  methods: {
    /**@method 显示详情
     * @param {Object} row - 当前点击行的值
     */
    showApplyModelFun(row) {
      this.showServiceApplyModel = true;
      this.serviceDetailsLoading = true;
      this.shopDetailsLoading = true;
      getParkIntoCompany({ intoId: row.intoId }).then((res) => {
        this.serviceDetailsLoading = false;
        this.shopDetailsLoading = false;
        if (res.data) {
          let data = res.data;
          this.serviceApplyDetails = {
            companyName: data.companyName,
            name: data.name,
            phone: data.phone,
            typeList: data.typeList,
            status: data.status,
          };
        }
      });
    },
    /**@method 编辑进驻服务大厅信息 */
    async showUpdateModelFun(row) {
      this.showUpdateModel = true;
      let params={
        typeUse: 0,
      };
      if(row.parkId){
        this.formData.parkId = row.parkId;
        delete this.formData.associationId
        params.parkId=row.parkId;
      }else{
        this.formData.associationId = row.associationId;
        delete this.formData.parkId
        params.associationId=row.associationId;
      }
      let res = await getParkShopTypeLinkList(params);
      if (res.data) {
        let typeData = [];
        for (let row of res.data) {
          if (row.linkId) {
            typeData.push(row);
          }
        }
        this.typeData = typeData;
      }
    },
    /**@method 修改申请信息 */
    updateFun() {
      let that = this;
      that.$refs.formData.validate((valid) => {
        if (valid) {
          let params = {
            ...that.formData,
            companyId: that.companyId,
          };
          that.loadingBtn = true;
          setParkIntoCompany(params)
            .then((res) => {
              that.loadingBtn = false;
              if (res.code === 200) {
                that.$message({
                  message: "修改申请完成",
                  type: "success",
                });
                that.showUpdateModel = false;
                that.getParkIntoCompanyList();
              } else {
                that.$message({
                  message: res.message,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              that.loadingBtn = false;
              if (err.code == 201) {
                this.$message.error(err.message);
              }
            });
        }
      });
    },
    /**@method 获取进驻服务大厅列表 */
    getParkIntoCompanyList() {
      let that = this;
      that.tableDataLoading = true;
      getParkIntoCompanyList({
        companyId: that.companyId,
        isGroup: 0,
        isPage: 0,
        intoType: 0,
      })
        .then((res) => {
          that.tableDataLoading = false;
          let tableData = [];
          for (let row of res.data) {
            if (row.typeList) {
              let typeList = [];
              for (let child of row.typeList) {
                typeList.push(child.typeName);
              }
              row.typeTitle = typeList.join("、");
            }
            tableData.push(row);
          }
          that.tableData = tableData;
        })
        .catch((err) => {
          that.tableDataLoading = false;
          that.tableData = [];
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
  display: none;
}

.corporateInformation-view {
  padding: 16px 24px;
}

/deep/.el-tabs__content {
  background: #ffffff;
}

.ruleForm {
  margin-top: 24px;
}

.cancel {
  border: 1px solid rgba(81, 203, 205, 0.4);
  color: #51cbcd;
}

.save {
  background: #51cbcd;
  border: 1px solid #51cbcd;
}

.myCardView {
  display: flex;
  flex-wrap: wrap;
}

.myCardItem {
  width: 240px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.d-flex {
  position: relative;
}
</style>