<template>
  <div>
    <div class="pa24">
      <div class="d-flex">
        <div style="margin-left: auto">
          <el-button
            class="ma mr10"
            type="primary"
            @click="addParkFun"
            :disabled="!companyId"
            >关联园区
          </el-button>
        </div>
      </div>
      <commonTable
        :tableData="linkParkTable"
        :loading="linkParkTableLoading"
        :paginationVisible="false"
      >
        <template v-slot:table>
          <el-table-column type="expand">
            <template slot-scope="props">
              <div
                class="myCardView"
                v-if="props.row.houseManageResponseList.length > 0"
              >
                <div
                  class="myCardItem"
                  v-for="(row, index) in props.row.houseManageResponseList"
                  :key="index"
                >
                  <el-card :body-style="{ padding: '10px' }" shadow="always">
                    <div class="d-flex">
                      <div class="leftIcon flex-c-c">
                        <div
                          class="el-icon-school"
                          style="font-size: 30px"
                        ></div>
                      </div>
                      <div class="rightText">
                        <div>{{ row.buildName }}</div>
                        <div>{{ row.number }}单元/{{ row.houseName }}</div>
                        <div
                          class="time mt3"
                          style="color: #f56c6c"
                          v-if="row.endTime && time > row.endMS"
                        >
                          已到期
                        </div>
                        <div
                          class="time mt3"
                          v-if="row.endTime && time < row.endMS"
                        >
                          {{ row.endTime }} 到期
                        </div>
                        <div class="time mt3" v-if="!row.endTime">永 久</div>
                      </div>
                      <!-- <div class="renew">续 费</div> -->
                    </div>
                  </el-card>
                </div>
              </div>
              <div v-else class="myCardView fwbold flex-c-c">暂无房屋内容</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="parkName"
            align="center"
            label="园区名称"
            show-overflow-tooltip
          />
          <el-table-column
            prop="linkman"
            align="center"
            label="联系人"
            show-overflow-tooltip
          />
          <el-table-column
            prop="linkmanPhone"
            align="center"
            label="联系电话"
            show-overflow-tooltip
          />
          <el-table-column align="center" label="操作" width="200">
            <template slot-scope="scope">
              <el-button type="text" @click="addParkHouseFun(scope.row)"
                >关联园区</el-button
              >
              <el-button
                style="color: #f56c6c"
                type="text"
                @click="secedePrakFun(scope.row)"
                >退出园区
              </el-button>
            </template>
          </el-table-column>
        </template>
      </commonTable>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      title="关联园区"
      :visible.sync="showAddParkModel"
      :destroy-on-close="true"
      width="350px"
    >
      <el-form
        :model="linkParkData"
        :rules="linkParkRules"
        ref="linkParkData"
        label-width="50px"
        class="ruleForm"
      >
        <el-form-item label="园区" prop="parkId">
          <el-select
            v-model="linkParkData.parkId"
            :loading="loadingPark"
            style="width: 240px"
            filterable
            remote
            :remote-method="getParkManageList"
            @change="selectParkFun"
            placeholder="请选择园区"
          >
            <el-option
              v-for="item in parkData"
              :key="item.parkId"
              :label="item.parkName"
              :value="item.parkId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="房屋" prop="houseId">
          <el-select
            v-model="linkParkData.houseId"
            :loading="loadingHouse"
            style="width: 240px"
            :disabled="isDisabledHouse"
            placeholder="楼宇/单元/房屋名称"
            filterable
            remote
            :remote-method="getHouseManageList"
          >
            <el-option
              v-for="item in houseData"
              :key="item.houseId"
              :label="item.name"
              :value="item.houseId"
              :disabled="item.disabled"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="save" type="primary" @click="linkParkAndCompany()"
            >关联园区</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getHouseManageList } from "@/api/propertyServices";
import {
  getParkManageList,
  linkParkAndCompany,
  getParkCompanyLink,
  delParkCompanyLink,
} from "@/api/parkManagement";
import commonTable from "@/components/common/commonTable";
export default {
  name: "corporateInformation",
  components: { commonTable },
  data() {
    return {
      activeName: "first",
      //加入园区配置
      companyId: 0, //公司id
      houseData: {}, //房屋数据
      parkData: {}, //园区数据
      isDisabledHouse: true, //是否禁用房屋选项
      linkParkData: {
        //关联信息
        houseId: "",
        parkId: "",
      },
      showAddParkModel: false, //设置显示进驻表单
      linkParkRules: {
        //验证提交数据
        parkId: [{ required: true, message: "请选择园区", trigger: "change" }],
        houseId: [{ required: true, message: "请选择房屋", trigger: "change" }],
      },
      linkParkTable: [], //关联园区数据
      linkParkTableLoading: false, //关联园区数据加载状态
      loadingHouse: false, //房屋数据加载状态
      loadingPark: false, //园区数据加载状态
      time: new Date().getTime(),
    };
  },
  async mounted() {
    this.companyId = this.$store.state.loginRoot.userInfo.companyId;
    this.getParkCompanyLink();
  },
  methods: {
    /**@method 显示进驻表单 */
    addParkFun() {
      this.showAddParkModel = true;
      this.linkParkData = {
        houseId: "",
        parkId: "",
      };
      this.houseData = {};
      this.isDisabledHouse = true;
      this.getParkManageList();
    },
    /**@method 选中园区显示进驻表单 */
    async addParkHouseFun(row) {
      this.showAddParkModel = true;
      this.getParkManageList(row.parkName);
      this.selectParkFun(row.parkId);
    },
    /**@method 退出园区 */
    secedePrakFun(row) {
      this.$confirm("此操作将把公司移出园区, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delParkCompanyLink({
            companyId: row.companyId,
            parkId: row.parkId,
          })
            .then((res) => {
              this.$message({
                type: "success",
                message: "踢出完成",
              });
              this.getParkCompanyLink();
            })
            .catch((err) => {
              this.$message({
                type: "error",
                message: err.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    /**@method 关联园区房屋 */
    linkParkAndCompany() {
      let that = this;
      that.$refs.linkParkData.validate((valid) => {
        if (valid) {
          let params = {
            ...that.linkParkData,
            companyId: that.companyId,
          };
          linkParkAndCompany(params)
            .then((res) => {
              that.$message({
                message: "进驻成功",
                type: "success",
              });
              that.showAddParkModel = false;
              that.getParkCompanyLink();
            })
            .catch((err) => {
              that.$message({
                message: err.message,
                type: "error",
              });
            });
        }
      });
    },
    /**@method 用园区id获取房屋 */
    selectParkFun(val) {
      this.isDisabledHouse = false;
      this.linkParkData.parkId = val;
      this.linkParkData.houseId = "";
      this.getHouseManageList();
    },
    /**@method 获取房屋数据 */
    async getHouseManageList(houseName) {
      if (this.linkParkData.parkId) {
        this.loadingHouse = true;
        let res = await getHouseManageList({
          parkId: this.linkParkData.parkId,
          pageNum: 1,
          pageSize: 30,
          houseName,
        });
        this.loadingHouse = false;
        try {
          let houseData = {};
          for (let row of res.data.pageInfo.list) {
            row.name =
              row.buildName + "/" + row.number + "单元" + row.houseName;
            row.disabled = row.familyId ? true : false;
            houseData[row.houseId] = row;
          }
          this.houseData = houseData;
        } catch (err) {
          this.houseData = {};
        }
      } else {
        this.houseData = {};
      }
    },
    /**@method 获取园区数据 */
    async getParkManageList(parkName) {
      this.loadingPark = true;
      let res = await getParkManageList({
        pageNum: 1,
        pageSize: 30,
        parkName,
        getAll: 1,
      });
      this.loadingPark = false;
      try {
        let parkData = {};
        for (let row of res.data.pageInfo.list) {
          parkData[row.parkId] = row;
        }
        this.parkData = parkData;
      } catch (err) {
        this.parkData = {};
      }
    },
    /**@method 获取我加入的园区列表 */
    getParkCompanyLink() {
      let that = this;
      that.linkParkTableLoading = true;
      getParkCompanyLink({
        companyId: that.companyId,
      })
        .then((res) => {
          that.linkParkTableLoading = false;
          let tableData = [];
          for (let row of res.data) {
            let houseManageResponseList = [];
            if (
              row.houseManageResponseList &&
              row.houseManageResponseList.length > 0
            ) {
              for (let child of row.houseManageResponseList) {
                child.endMS = new Date(child.endTime);
                houseManageResponseList.push(child);
              }
            }
            row.houseManageResponseList = houseManageResponseList;
            tableData.push(row);
          }
          that.linkParkTable = tableData;
        })
        .catch((err) => {
          that.linkParkTableLoading = false;
          that.linkParkTable = [];
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
  display: none;
}

.corporateInformation-view {
  padding: 16px 24px;
}

/deep/.el-tabs__content {
  background: #ffffff;
}

.ruleForm {
  margin-top: 24px;
}

.cancel {
  border: 1px solid rgba(81, 203, 205, 0.4);
  color: #51cbcd;
}

.save {
  background: #51cbcd;
  border: 1px solid #51cbcd;
}

.myCardView {
  display: flex;
  flex-wrap: wrap;
}

.myCardItem {
  width: 240px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.leftIcon {
  background: #51cbcd;
  font-size: 33px;
  width: 60px;
  height: 60px;
  color: #fff;
  border-radius: 50%;
}

.rightText {
  font-size: 15px;
  margin-left: 10px;

  .time {
    color: #999;
    font-size: 12px;
  }
}

.d-flex {
  position: relative;
}

.secede {
  color: #f56c6c;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  font-size: 13px;
}

.renew {
  color: #51cbcd;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  font-size: 13px;
}
</style>