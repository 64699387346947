<template>
  <div class="corporateInformation-view">
    <el-tabs v-model="activeName">
      <el-tab-pane label="企业信息" name="companyInfo">
        <companyInfo v-if="activeName == 'companyInfo'" />
      </el-tab-pane>
      <el-tab-pane label="周边商家" name="surroundingMerchants">
        <surroundingMerchants v-if="activeName == 'surroundingMerchants'" />
      </el-tab-pane>
      <el-tab-pane label="服务大厅" name="serviceHall">
        <serviceHall v-if="activeName == 'serviceHall'" />
      </el-tab-pane>
      <el-tab-pane label="所在园区" name="park">
        <park v-if="activeName == 'park'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import companyInfo from './components/corporateInformation/companyInfo.vue'
import surroundingMerchants from './components/corporateInformation/surroundingMerchants.vue'
import serviceHall from './components/corporateInformation/serviceHall.vue'
import park from './components/corporateInformation/park.vue'

export default {
  name: "corporateInformation",
  components: { park, companyInfo, surroundingMerchants, serviceHall },
  data() {
    return {
      activeName: "companyInfo",

    };
  }
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
  display: none;
}

.corporateInformation-view {
  padding: 16px 24px;
}

/deep/.el-tabs__content {
  background: #ffffff;
}

.ruleForm {
  margin-top: 24px;
}

.cancel {
  border: 1px solid rgba(81, 203, 205, 0.4);
  color: #51cbcd;
}

.save {
  background: #51cbcd;
  border: 1px solid #51cbcd;
}

.myCardView {
  display: flex;
  flex-wrap: wrap;
}

.myCardItem {
  width: 240px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.leftIcon {
  background: #51CBCD;
  font-size: 33px;
  width: 60px;
  height: 60px;
  color: #fff;
  border-radius: 50%;
}

.rightText {
  font-size: 15px;
  margin-left: 10px;

  .time {
    color: #999;
    font-size: 12px;
  }
}

.d-flex {
  position: relative;
}

.secede {
  color: #F56C6C;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  font-size: 13px;
}

.renew {
  color: #51CBCD;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  font-size: 13px;
}
</style>