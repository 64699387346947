<template>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="dialogTitle" :visible.sync="showDialog" :destroy-on-close="true"
        width="350px">
        <el-form :model="formData" :rules="rules" ref="formData" label-width="80px" class="ruleForm">
            <el-form-item label="商品图片" require prop="goodsImg">
                <el-upload :action="uploadUrl" list-type="picture-card" :class="{ disabled: uploadDisabled }"
                    :on-remove="handleRemove" :on-success="handleSuccess" :file-list="imgList" :limit="1">
                    <i slot="default" class="el-icon-plus"></i>
                    <!-- <div slot="tip" class="el-upload__tip">上传图片</div> -->
                </el-upload>
            </el-form-item>
            <el-form-item label="商品名称" prop="goodsName">
                <el-input v-model="formData.goodsName"></el-input>
            </el-form-item>
            <el-form-item label="商品价格" prop="goodsPrice">
                <el-input class="suffixInput" type="number" v-model="formData.goodsPrice">
                    <template slot="append">元</template>
                </el-input>
            </el-form-item>
            <el-form-item label="让利率" class="suffixInput" v-if="isScale" type="number" prop="discountScale">
                <el-input v-model="formData.discountScale">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button class="save" type="primary" @click="submitProduct()" :loading="loadingBtn">提 交</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
export default {
    props: {
        isScale: {//是否显示让利率
            type: Boolean,
            default: false,
        },
        defaultData: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            loadingBtn: false,
            dialogTitle: "添加商品",
            uploadUrl: this.$store.state.uploadingUrl,
            imgList: [],
            ossUrl: this.$store.state.ossUrl,
            showDialog: false,
            formData: {
                goodsId: "",
                goodsImg: "",
                goodsName: "",
                goodsPrice: "",
                discountScale: "",
                isDel:0
            },
            rules: {//验证提交数据
                goodsImg: [
                    { required: true, message: "请上传图片" },
                ],
                goodsName: [
                    { required: true, message: "请输入商品名称", trigger: "blur" },
                ],
                goodsPrice: [
                    { required: true, message: "请输入商品价格", trigger: "blur" },
                ],
            },
        }
    },
    computed: {
        uploadDisabled() {
            return this.imgList.length > 0
        },
        watchDefaultData() {
            return this.defaultData;
        }
    },
    watch: {
        watchDefaultData(data) {
            let { goodsId, goodsImg, goodsName, goodsPrice, discountScale,isDel } = data;
            this.formData = {
                goodsId: goodsId ? goodsId : "",
                goodsImg,
                goodsName,
                goodsPrice,
                discountScale: discountScale ? discountScale : 0,
                isDel
            };
            if (goodsImg) {
                this.imgList = [{
                    url: goodsImg
                }]
            } else {
                this.imgList = [];
            }
        }
    },
    methods: {
        submitProduct() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    let params = {
                        ...this.formData
                    }
                    if (!this.formData.goodsId) {
                        delete params.goodsId
                    }
                    if (!this.isScale) {
                        delete params.discountScale
                    }
                    this.formData = {
                        goodsId: "",
                        goodsImg: "",
                        goodsName: "",
                        goodsPrice: "",
                        discountScale: "",
                        isDel:0
                    };
                    this.imgList = [];
                    this.$emit("submitProduct", params)
                    this.showDialog = false;
                }
            })
        },
        /**@method 删除图片 */
        handleRemove(file, fileList) {
            //-console.log(file, fileList);
            this.imgList = []
            this.formData.goodsImg = ''
        },
        /**@method 上传完成回调 */
        handleSuccess(response, file, fileList) {
            this.$message.closeAll();
            this.$message({
                message: "上传成功",
                type: "success",
            });
            //-console.log('file', file)
            this.formData.goodsImg = this.ossUrl + file.response.data
            //-console.log(this.companyMsg)
            this.imgList = fileList;
        },
    }
}
</script>

<style scoped lang="scss">
/deep/ .disabled .el-upload--picture-card {
    display: none;
}
</style>